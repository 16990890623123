import React from 'react';
import "./Footer.css"

const Footer = () => {
    return (
        <div className="bgFooter bg">
            <p className="footer bg">©Staley Millenium Falcons 2024</p>
        </div>
    );
};

export default Footer;